import React from 'react';
import { Flex } from '@chakra-ui/react';

export default function Tag({ children, isHover = false, ...props }) {
  return (
    <Flex
      as="p"
      boxSizing="border-box"
      w="fit-content"
      borderRadius="100px"
      borderWidth={{ laptop: '1.6px', mobile: '1px' }}
      borderStyle="solid"
      borderColor="bg.black"
      textStyle="tag"
      p={{ mobile: '6.5px 12px', laptop: '7.5px 16px' }}
      {...props}
    >
      {children}
    </Flex>
  );
}
