export const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    zIndex: 1,
    width: '6px',
    bgColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    zIndex: 1,
    bgColor: 'bg.grayLight',
    borderRadius: '24px',
  },
  '&::-webkit-scrollbar-thumb': {
    zIndex: 1,
    bgColor: 'bg.accent',
    borderRadius: '24px',
  },
  '::-webkit-scrollbar-track-piece:end': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-track-piece:start': {
    background: 'transparent',
  },
};
