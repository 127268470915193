import React from 'react';
import { HStack, Square, Stack, VStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { podcastsSelector } from 'app/selectors/podcastsSelector';
import { usePodcastsPlayerContext } from 'shared/PodcastsPlayerContext';
import PodcastAudioProgress from 'shared/PodcastAudioProgress';
import PlayerControl from 'shared/PodcastsPlayerControl';
import VolumeControl from 'shared/VolumeControl';
import ScrolledText from 'shared/ScrolledText';

export default function PodcastsPlayer() {
  const { allPodcasts, activeTrackId } = useBoundStore(podcastsSelector);
  const { volume, setVolume } = usePodcastsPlayerContext();

  return (
    <VStack
      w="100dvw"
      pos="fixed"
      bottom={0}
      left={0}
      bgColor="rgba(246, 246, 246, 0.5)"
      backdropFilter="blur(20px)"
      alignItems="start"
      p={{ mobile: '8px 20px 0', laptop: '8px 40px 0', desktop: '8px 80px 0' }}
      gap="4px"
      zIndex={900}
    >
      <ScrolledText>{allPodcasts[activeTrackId]?.title}</ScrolledText>
      <Stack
        w="full"
        direction={{ mobile: 'column', tablet: 'row' }}
        spacing={{ mobile: '12px', tablet: '26px' }}
        align="center"
        whiteSpace="nowrap"
      >
        <PlayerControl hideBelow="tablet" />
        <HStack w="full" spacing="24px" align="center" justify="space-between" hideFrom="tablet">
          <Square size="24px" visibility="hidden" />
          <PlayerControl hideFrom="tablet" />
          <VolumeControl volume={volume} onVolumeChange={setVolume} imgProps={{ filter: 'none' }} />
        </HStack>
        <PodcastAudioProgress />
        <HStack spacing="24px" align="center" hideBelow="tablet">
          <VolumeControl volume={volume} onVolumeChange={setVolume} imgProps={{ filter: 'none' }} />
        </HStack>
      </Stack>
    </VStack>
  );
}
