import React from 'react';

import Greetings from 'widgets/GreetingsRadio';
import NewEpisodes from 'widgets/NewEpisodes';
import Rubrics from 'widgets/Rubrics';
import Top8Episodes from 'widgets/Top8Episodes';
import SocialsMedia from 'widgets/SocialsMedia';
import PodcastsPlayer from 'features/PodcastsPlayer';
import { PodcastsPlayerProvider } from 'shared/PodcastsPlayerContext';
import Wrapper from 'shared/Wrapper';

export default function Radio() {
  return (
    <PodcastsPlayerProvider>
      <Greetings />
      <Wrapper m="0" pt="80px" pb={{ laptop: '180px', tablet: '120px', mobile: '80px' }}>
        <NewEpisodes />
        <Rubrics />
        <Top8Episodes />
        <SocialsMedia />
      </Wrapper>
      <PodcastsPlayer />
    </PodcastsPlayerProvider>
  );
}
