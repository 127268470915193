import { podcastsSelector } from 'app/selectors/podcastsSelector';
import { useBoundStore } from 'app/store';
import React, { createContext, useContext, useEffect } from 'react';
import { useAudioPlayer, useGlobalAudioPlayer } from 'react-use-audio-player';

const PodcastsPlayerContext = createContext({
  playing: false,
  togglePlayPause: () => {},
  volume: 0.1,
  setVolume: volume => {},
  getPosition: () => {},
  duration: 0,
  seek: seconds => {},
});

export const PodcastsPlayerProvider = ({ children }) => {
  const player = useAudioPlayer();
  const { pause, playing } = useGlobalAudioPlayer();
  const { allPodcasts, activeTrackId, setActiveTrack } = useBoundStore(podcastsSelector);

  useEffect(() => {
    allPodcasts?.[activeTrackId] &&
      player.load(allPodcasts[activeTrackId]?.media_url, {
        autoplay: true,
        html5: true,
        format: 'mp3',
        initialVolume: 0.1,
        onend: () => allPodcasts[activeTrackId - 1] && setActiveTrack(activeTrackId - 1),
      });
  }, [activeTrackId, player.load, allPodcasts]);

  useEffect(() => {
    playing && player.pause();
    player.playing && pause();
  }, [player.playing, playing]);

  return <PodcastsPlayerContext.Provider value={player}>{children}</PodcastsPlayerContext.Provider>;
};

export const usePodcastsPlayerContext = () => useContext(PodcastsPlayerContext);
