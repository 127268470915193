function findRubricById(rubrics, id) {
  for (const key in rubrics) {
    if (rubrics[key].id === id) {
      return rubrics[key];
    }
  }
}

export const transformLatestData = (latest, rubrics) =>
  latest.map(({ id, rubric_id, title, description }) => ({
    id,
    title,
    description: description?.replace('\n', '<br />'),
    tag: findRubricById(rubrics, rubric_id)?.name,
    img: findRubricById(rubrics, rubric_id)?.img_medium,
  }));
