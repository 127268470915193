import React, { useState } from 'react';
import { Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

import Wrapper from 'shared/Wrapper';
import PlayPauseButton from 'shared/PlayPauseButton';

import illustration from 'assets/Radio/greetings/illustration.png';
import underline from 'assets/Radio/greetings/underline.svg';

export default function GreetingsRadio() {
  const { togglePlayPause, playing } = useGlobalAudioPlayer();
  const [wasPlayed, setWasPlayed] = useState(false);

  const handlePlayPause = () => {
    if (!wasPlayed) {
      setWasPlayed(true);
    }
    togglePlayPause();
  };

  return (
    <Wrapper
      as="section"
      mt={{ mobile: '42px', tablet: '72px', md: '84px', laptop: '107px' }}
      p={{ mobile: '52px 0px', tablet: '32px 20px', laptop: '48px 172px', desktop: '32px 172px' }}
      gap="0"
    >
      <VStack w="fit-content" pos="relative" spacing={{ mobile: '12px', tablet: '16px' }}>
        <Text
          as="h1"
          textStyle="header.h1"
          fontSize={{ mobile: '40px', tablet: '52px', laptop: '64px', desktop: '80px' }}
          fontWeight="700"
        >
          ASTON Подкаст
        </Text>
        <Text
          as="h2"
          textStyle="header.h2"
          fontSize={{ mobile: '24px', laptop: '32px', desktop: '48px' }}
        >
          О мире IT и его обитателях
        </Text>
        <Image
          src={underline}
          alt="text underline"
          pos="absolute"
          w={{ mobile: '192px', tablet: '215px', laptop: '340px', desktop: '365px' }}
          top={{ mobile: '-6px', tablet: '2px', laptop: '-5px', desktop: '4px' }}
          right={{ mobile: '-29px', tablet: '0px', laptop: '-65px', desktop: '-35px' }}
          zIndex="-1"
        />
      </VStack>
      <Image
        src={illustration}
        alt="greetings illustration"
        w="full"
        maxW="1070px"
        minW="705px"
        p="31px 0 12px"
      />
      <Button
        size="secondary"
        border="none"
        _hover={{}}
        bgColor="bg.accent"
        p="8px 32px 8px 8px"
        onClick={handlePlayPause}
      >
        <HStack spacing={{ mobile: '12px', laptop: '20px' }}>
          <PlayPauseButton
            isPlaying={playing}
            onPlayPauseClick={handlePlayPause}
            border="2.1px solid"
            boxSize={{ mobile: '48px', laptop: '72px' }}
            imgProps={{
              boxSize: { mobile: '18px', laptop: '28px' },
            }}
          />
          <Text
            as="p"
            textStyle="button.primary"
            lineHeight="120%"
            letterSpacing={{ tablet: '-0.02em' }}
          >
            Слушать радио
          </Text>
        </HStack>
      </Button>
    </Wrapper>
  );
}
