import illustration from 'assets/Radio/top8Episodes/episode-illustration.png';
import { msToTime } from 'shared/lib/msToTime';

export const transformTop8Data = podcastsData =>
  podcastsData.map(({ id, title, description, duration }) => ({
    id,
    title,
    description,
    img: illustration,
    duration: msToTime(duration),
  }));
