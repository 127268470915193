export const podcastsSelector = ({
  allPodcasts,
  rubrics,
  latest,
  top8,
  activeTrackId,
  createRubrics,
  addRubricPodcasts,
  addRubricsPodcasts,
  setActiveTrack,
}) => ({
  allPodcasts,
  rubrics,
  latest,
  top8,
  activeTrackId,
  createRubrics,
  addRubricPodcasts,
  addRubricsPodcasts,
  setActiveTrack,
});
