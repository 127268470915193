import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Image,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import Lottie from 'lottie-react';
import React, { useEffect } from 'react';

import { useBoundStore } from 'app/store';
import { podcastsSelector } from 'app/selectors/podcastsSelector';
import { usePodcastsPlayerContext } from 'shared/PodcastsPlayerContext';
import Api from 'shared/backApi';

import illustration from 'assets/Radio/top8Episodes/illustration.svg';
import animation from 'assets/animations/musicSounds.json';
import { transformTop8Data } from './lib';

export default function Top8Episodes() {
  const { rubrics, top8, addRubricPodcasts, activeTrackId, setActiveTrack } =
    useBoundStore(podcastsSelector);
  const { playing, togglePlayPause } = usePodcastsPlayerContext();
  const isLoaded = Object.keys(rubrics)?.length;

  const handlePlayPauseButtonClick = id => () => {
    setActiveTrack(id);
    togglePlayPause();
  };

  useEffect(() => {
    async function fetchRadioData() {
      try {
        const podcasts = await Api.getTop8Podcasts();
        addRubricPodcasts('top8', podcasts);
      } catch (e) {
        return e;
      }
    }
    isLoaded && fetchRadioData();
  }, [isLoaded]);

  return (
    <VStack as="section" w="full" spacing={{ mobile: '24px', laptop: '48px' }}>
      <Stack
        w="full"
        px={{ xxxl: '145.5px' }}
        direction={{ mobile: 'column', tablet: 'row' }}
        spacing={{ mobile: '32px', tablet: '48px' }}
        align="center"
      >
        <Image
          src={illustration}
          alt="illustration"
          w={{ mobile: '200px', xxl: '388px' }}
          h="full"
          flexShrink="0"
        />
        <VStack spacing={{ mobile: '12px', tablet: '16px', laptop: '24px' }}>
          <Text as="h3" textStyle="header.h4" fontSize={{ mobile: '24px', laptop: '32px' }}>
            Мы собрали для вас самые актуальные и вдохновляющие выпуски подкастов за последний месяц
          </Text>
          <Text as="p" textStyle="h2" fontWeight={{ mobile: '500', tablet: '400' }}>
            Здесь вы найдёте практические советы от экспертов, захватывающие истории успеха
            и увлекательные беседы о будущем IT
          </Text>
        </VStack>
      </Stack>
      <Flex
        w="full"
        p={{ mobile: '12px', tablet: '32px', laptop: '60px' }}
        border="1.6px solid"
        borderColor="bg.gray"
        borderRadius={{ mobile: '20px', tablet: '24px', laptop: '48px' }}
      >
        <Grid
          w="full"
          templateColumns={{ mobile: '1fr', xxl: '1fr 1fr' }}
          rowGap={{ mobile: '24px', tablet: '32px', laptop: '12px' }}
          columnGap="40px"
        >
          {(isLoaded && top8?.length
            ? transformTop8Data(top8)
            : new Array(8).fill({ isLoaded: false })
          ).map(({ id, img, title, description, duration, isLoaded = true }, i) => (
            <HStack
              key={id ?? i}
              w="full"
              align="center"
              spacing={{ mobile: '8px', tablet: '16px' }}
              p={{ laptop: '16px 20px' }}
              borderRadius={{ laptop: '20px' }}
              cursor="pointer"
              transition="all .2s"
              _hover={
                activeTrackId !== id && {
                  bgColor: 'bg.grayLight',
                }
              }
              onClick={handlePlayPauseButtonClick(id)}
            >
              <Skeleton borderRadius={{ mobile: '9px', laptop: '13px' }} isLoaded={isLoaded}>
                <Box
                  pos="relative"
                  boxSize={{ mobile: '40px', tablet: '48px', laptop: '72px' }}
                  borderRadius={{ mobile: '9px', laptop: '13px' }}
                  flexShrink="0"
                  bgColor="bg.gray"
                >
                  <Image
                    src={img}
                    alt="episode illustration"
                    boxSize="full"
                    pos="absolute"
                    top="0"
                    left="0"
                    borderRadius={{ mobile: '9px', laptop: '13px' }}
                  />

                  <Center
                    boxSize="full"
                    pos="absolute"
                    top="0"
                    left="0"
                    bgColor={playing ? 'rgba(13, 14, 12, 0.6)' : 'bg.black'}
                    transition="all .2s"
                    opacity={activeTrackId === id && playing ? '1' : '0'}
                    borderRadius={{ mobile: '9px', laptop: '13px' }}
                  >
                    <Lottie
                      animationData={animation}
                      autoplay
                      loop
                      style={{ width: '24px', height: '24px' }}
                    />
                  </Center>
                </Box>
              </Skeleton>

              <SkeletonText
                w="full"
                noOfLines={2}
                spacing="3"
                skeletonHeight="5"
                isLoaded={isLoaded}
              >
                <HStack
                  w="full"
                  align="start"
                  justify="space-between"
                  spacing={{ mobile: '12px', tablet: '20px' }}
                >
                  <VStack align="start">
                    <Text
                      as="h4"
                      textStyle="h4"
                      fontSize={{ mobile: '14px', tablet: '16px', laptop: '18px' }}
                      fontWeight={activeTrackId === id && playing ? 700 : 500}
                      transition="all .2s"
                      noOfLines={1}
                    >
                      {title}
                    </Text>
                    <Text
                      as="p"
                      textStyle="h2"
                      fontWeight={{ mobile: '500', laptop: '400' }}
                      noOfLines={1}
                    >
                      {description}
                    </Text>
                  </VStack>
                  <Text
                    as="p"
                    color="#575C66"
                    textStyle="h3"
                    fontSize={{ mobile: '12px', laptop: '16px' }}
                  >
                    {duration}
                  </Text>
                </HStack>
              </SkeletonText>
            </HStack>
          ))}
        </Grid>
      </Flex>
    </VStack>
  );
}
