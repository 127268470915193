import React, { memo } from 'react';
import { Img } from '@chakra-ui/react';

import Prev from 'assets/Icons/PreviousTrackButton.svg';

const SelectTrackButton = ({ variant, onClick, isDisabled = false, imgProps = null, ...props }) => (
  <Img
    src={Prev}
    boxSize={{ mobile: '40px', laptop: '42px' }}
    transform={variant === 'right' && 'rotate(180deg)'}
    filter={`invert(${
      isDisabled ? 90 : 41
    }%) sepia(6%) saturate(337%) hue-rotate(177deg) brightness(91%) contrast(88%)`}
    transition="all .2s"
    _hover={
      !isDisabled && {
        filter: 'none',
      }
    }
    cursor={isDisabled ? 'default' : 'pointer'}
    onClick={onClick}
    {...props}
  />
);

export default memo(SelectTrackButton);
