import React from 'react';
import Lottie from 'lottie-react';
import { Accordion, HStack, Image, SkeletonText, Stack, Text, VStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { podcastsSelector } from 'app/selectors/podcastsSelector';
import { usePodcastsPlayerContext } from 'shared/PodcastsPlayerContext';
import { workWithTrackId } from 'shared/lib/workWithTrackId';
import AccordionInfo from 'shared/AccordionInfo';
import { msToTime } from 'shared/lib/msToTime';

import animation from 'assets/animations/musicSounds.json';
import { scrollBarStyle } from './model/scrollBarStyle';

export default function RubricCard({ name, description, img_big, episodes }) {
  const { rubrics, activeTrackId, setActiveTrack } = useBoundStore(podcastsSelector);
  const { togglePlayPause } = usePodcastsPlayerContext();
  const isEpisodesLoaded = Boolean(episodes?.length);

  const handlePlayPauseButtonClick = accordionId => {
    accordionId >= 0 && setActiveTrack(rubrics[name].episodes[accordionId].id);
    togglePlayPause();
  };

  return (
    <Stack
      w="full"
      pos="relative"
      h={{ laptop: '466px' }}
      direction={{ mobile: 'column', laptop: 'row' }}
      p={{ mobile: '20px', laptop: '40px 40px 22px' }}
      border="1.6px solid"
      borderColor="line.gray"
      borderRadius={{ mobile: '24px', laptop: '48px' }}
      gap={{ mobile: '85px', tablet: '88px', laptop: '83px' }}
      justify={{ laptop: 'space-between' }}
      overflow="hidden"
    >
      <VStack
        align="start"
        maxW={{ mobile: '211px', tablet: '420px', laptop: '582px' }}
        spacing="8px"
      >
        <Text as="h3" textStyle="header.h3">
          {name}
        </Text>
        <Text as="p" textStyle="h1" fontWeight="500">
          {description}
        </Text>
      </VStack>
      <Image
        src={img_big}
        alt="rubric illustration"
        pos="absolute"
        w={{ mobile: '421px', laptop: '790px' }}
        minW="421px"
        top={{ mobile: '-20px', laptop: 'unset' }}
        bottom={{ mobile: 'unset', laptop: '-187px' }}
        left={{ mobile: 'unset', laptop: '-37px' }}
        right={{ mobile: '-195px', tablet: '-99px', laptop: 'unset' }}
        zIndex="-1"
      />
      <VStack
        align="start"
        w={{ mobile: 'full', laptop: '615px' }}
        spacing="8px"
        maxH={{ mobile: '326px', tablet: '367px', laptop: '390px' }}
      >
        <Text
          as="h4"
          textStyle={{ mobile: 'h4', laptop: 'h2' }}
          fontSize={{ mobile: '12px', laptop: '16px' }}
        >
          Выпуски
        </Text>

        <SkeletonText
          w="full"
          overflow="auto"
          noOfLines={10}
          spacing="5"
          skeletonHeight="4"
          isLoaded={isEpisodesLoaded}
        >
          <Accordion
            allowToggle
            sx={scrollBarStyle}
            flex={1}
            w="full"
            gap={{ mobile: '8px', tablet: '0' }}
            display="flex"
            flexDirection="column"
            overflow="auto"
            pr="8px"
            _expanded={{ bgColor: 'bg.gray' }}
            index={workWithTrackId(activeTrackId, 'getId') - 1}
            onChange={handlePlayPauseButtonClick}
          >
            {episodes?.map(({ id, title, description, duration }) => (
              <AccordionInfo
                key={id}
                RenderTitle={
                  <HStack w="full" justify="space-between">
                    <Text
                      as="h4"
                      textStyle="h2"
                      fontSize={{ mobile: '14px', tablet: '16px', laptop: '18px' }}
                      fontWeight={
                        activeTrackId === id
                          ? { mobile: '600', laptop: '500' }
                          : { mobile: '500', laptop: '400' }
                      }
                      lineHeight="170%"
                      textAlign="start"
                      textDecoration={activeTrackId !== id && 'underline'}
                      textDecorationColor="bg.gray"
                      textUnderlineOffset="6px"
                      noOfLines={1}
                    >
                      {title}
                    </Text>
                    <Text as="p" textStyle="h2" fontSize={{ mobile: '12px', laptop: '16px' }}>
                      {msToTime(duration)}
                    </Text>
                  </HStack>
                }
                content={description.replace('\n', '<br />')}
                leftIcon={
                  activeTrackId === id && (
                    <Lottie
                      animationData={animation}
                      autoplay
                      loop
                      style={{ width: '16px', height: '16px', flexShrink: '0' }}
                    />
                  )
                }
                contentSx={{
                  as: 'p',
                  textStyle: 'h2',
                  fontSize: { mobile: '12px', laptop: '16px' },
                  fontWeight: { mobile: '500', laptop: '400' },
                }}
                containerStyle={{
                  borderRadius: { mobile: '12px', tablet: '20px' },
                  gap: '10px',
                  p: { mobile: '8px', tablet: '16px 20px' },
                  bgColor: activeTrackId === id && 'bg.grayLight',
                }}
                panelStyle={{
                  textStyle: 'button.secondary',
                  overflow: 'visible',
                  p: '16px 0 0',
                }}
              />
            ))}
          </Accordion>
        </SkeletonText>
      </VStack>
    </Stack>
  );
}
