import React, { useEffect, useState } from 'react';
import { Skeleton, Text, VStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { podcastsSelector } from 'app/selectors/podcastsSelector';
import RubricCard from '~entities/RubricCard';
import BlockHeader from 'shared/BlockHeader';
import Switcher from 'shared/Switcher';
import Api from 'shared/backApi';

export default function Rubrics() {
  const [activeLabel, setActiveLabel] = useState('');
  const { rubrics, createRubrics, addRubricsPodcasts } = useBoundStore(podcastsSelector);
  const labels = Object.keys(rubrics);
  const isLoaded = Boolean(labels?.length);

  useEffect(() => {
    async function fetchRubrics() {
      try {
        const rubricsData = await Api.getPodcastsRubrics();
        createRubrics(rubricsData);
      } catch (e) {
        return e;
      }
    }
    fetchRubrics();
  }, []);

  useEffect(() => {
    if (isLoaded && !activeLabel) setActiveLabel(labels[0]);

    async function fetchRubricsPodcasts(rubricName, rubricId) {
      try {
        const podcastsData = await Api.getPodcastsByRubricId(rubricId);
        addRubricsPodcasts(rubricName, podcastsData);
      } catch (e) {
        return e;
      }
    }
    Object.entries(rubrics).forEach(([rubricName, { id }]) => {
      fetchRubricsPodcasts(rubricName, id);
    });
  }, [isLoaded]);

  return (
    <VStack w="full" align="start" spacing={{ mobile: '24px', laptop: '48px' }}>
      <VStack w="full" align="start" spacing={{ mobile: '16px', laptop: '24px' }}>
        <BlockHeader>Рубрики</BlockHeader>
        <Text as="p" textStyle="h2">
          Разнообразие рубрик в эфире радио <br />
          Открой для себя новые музыкальные, информационные и развлекательные стили
        </Text>
      </VStack>
      <Skeleton borderRadius={{ tablet: '48px', mobile: '28px' }} isLoaded={isLoaded}>
        <Switcher
          labels={isLoaded ? labels : new Array(3).fill('Loading')}
          activeLabel={activeLabel}
          onLabelClick={label => setActiveLabel(label)}
          btnSx={{
            textStyle: { mobile: 'h4', tablet: 'h2' },
            fontSize: { laptop: '18px', tablet: '12px', mobile: '16px' },
            p: { laptop: '14px 24px', tablet: '12px 24px', mobile: '9px 24px' },
          }}
        />
      </Skeleton>
      <Skeleton
        w="full"
        h={{ laptop: '466px' }}
        borderRadius={{ mobile: '24px', laptop: '48px' }}
        isLoaded={isLoaded}
      >
        <RubricCard {...rubrics[activeLabel]} />
      </Skeleton>
    </VStack>
  );
}
