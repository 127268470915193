import React, { useCallback, useEffect, useState, useRef } from 'react';
import { throttle } from 'utils';
import anime from 'animejs';
import { setCookie } from './cookie';

export const useIsSsr = () => {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSsr(false);
  }, []);

  return isSsr;
};
/**
 * Управление состоянием компонента Tooltip
 * @param dependencies {array} - зависимости для хука useCallback (для оптимизации)
 * @returns {{isOpen: boolean, showTooltip: function, hideTooltip: function}}
 */
export const useTooltipState = (dependencies = []) => {
  const [isOpen, setIsOpen] = useState(false);

  const showTooltip = useCallback(() => setIsOpen(true), dependencies);
  const hideTooltip = useCallback(() => setIsOpen(false), dependencies);

  return { isOpen, showTooltip, hideTooltip };
};

export const useStateModal = () => {
  const [modalState, setModalState] = useState({ isOpen: false });

  const showModal = useCallback(() => setModalState({ isOpen: true }), []);
  const hideModal = useCallback(() => setModalState({ isOpen: false }), []);

  return { modalState, showModal, hideModal };
};

export const useStateModals = () => {
  const [modalState, setModalState] = useState({});

  const showModal = useCallback(key => setModalState({ ...modalState, [key]: true }), []);
  const hideModal = useCallback(key => () => setModalState({ ...modalState, [key]: false }), []);

  return { modalState, showModal, hideModal };
};

export function useLoadingComponent(ApiCall, ...args) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    ApiCall(...args)
      .then(setData)
      .finally(() => setLoading(false));
  }, []);

  return { data, loading };
}

export function useDocumentScrollThrottled(callback) {
  const [, setScrollPosition] = useState(0);
  let previousScrollTop = 0;

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body;

    setScrollPosition(previousPosition => {
      previousScrollTop = previousPosition;
      return currentScrollTop;
    });

    callback({ previousScrollTop, currentScrollTop });
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250);

  useEffect(() => {
    window.addEventListener('scroll', handleDocumentScrollThrottled);

    return () => window.removeEventListener('scroll', handleDocumentScrollThrottled);
  }, []);
}

export const useClickOutside = (refs, handler) => {
  useEffect(() => {
    const targets = refs.map(el => el.current);

    const listener = e => {
      if (
        !targets.some(el => el?.contains && el.contains(e.target)) &&
        typeof handler === 'function'
      ) {
        handler();
      }
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [refs, handler]);
};

export const useDropDown = (headRef, dropDownRef, arrowUpRef, arrowDownRef, onClose) => {
  const animationDuration = 200;
  const animationElasticDuration = animationDuration * 3;
  const animationEasing = 'easeOutCubic';

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const isVisible = useRef(false);

  useEffect(() => {
    const dropdown = dropDownRef.current;

    dropdown.style.opacity = 0;
    dropdown.style.zIndex = -1;
    dropdown.style.transform = 'scale(0.98)';
  }, []);

  useLazyEffect(() => {
    if (isDropDownOpen) {
      open();
    } else {
      close();
    }
  }, [isDropDownOpen]);

  useClickOutside([headRef, dropDownRef], () => setIsDropDownOpen(false));

  const open = () => {
    const dropdown = dropDownRef.current;

    isVisible.current = true;
    anime.remove([arrowUpRef.current, arrowDownRef.current, dropDownRef.current]);

    anime({
      targets: arrowDownRef.current,
      translateY: [0, 5],
      opacity: [1, 0],
      duration: animationDuration,
      easing: animationEasing,
      complete: () => {
        anime({
          targets: arrowUpRef.current,
          translateY: [5, 0],
          rotateZ: {
            value: '180deg',
            duration: 0,
          },
          opacity: [0, 1],
          duration: animationDuration,
          easing: animationEasing,
        });
      },
    });

    anime({
      targets: dropDownRef.current,
      opacity: 1,
      scale: {
        value: 1,
        duration: animationElasticDuration,
        easing: 'easeOutElastic(4, .5)',
      },
      duration: animationDuration,
      easing: animationEasing,
      begin: () => {
        dropdown.style.zIndex = 999;
        dropdown.style.pointerEvents = 'auto';
      },
    });
  };

  const close = () => {
    const dropdown = dropDownRef.current;

    anime.remove([arrowUpRef.current, arrowDownRef.current, dropDownRef.current]);

    anime({
      targets: arrowUpRef.current,
      translateY: [0, -5],
      opacity: [1, 0],
      duration: animationDuration,
      easing: animationEasing,
      complete: () => {
        anime({
          targets: arrowDownRef.current,
          translateY: [-5, 0],
          opacity: [0, 1],
          duration: animationDuration,
          easing: animationEasing,
          complete: () => {
            if (typeof onClose === 'function') {
              onClose();
            }
          },
        });
      },
    });

    anime({
      targets: dropDownRef.current,
      opacity: 0,
      duration: animationDuration,
      easing: animationEasing,
      scale: 0.98,
      complete: () => {
        dropdown.style.zIndex = -1;
        dropdown.style.pointerEvents = 'none';
        isVisible.current = false;
      },
    });
  };

  return {
    open: () => setIsDropDownOpen(true),
    close: () => setIsDropDownOpen(false),
    toggle: () => {
      setIsDropDownOpen(!isDropDownOpen);
      return !isDropDownOpen;
    },
    isOpen: isDropDownOpen,
  };
};

export const useLazyEffect = (fn, deps) => {
  const isRendered = useRef(false);

  useEffect(() => {
    if (isRendered.current) {
      fn();
    } else {
      isRendered.current = true;
    }
  }, deps);
};

export const useWindowResize = () => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width };
};

const getUTMParams = url => {
  return Array.from(new URL(url).searchParams)
    .filter(n => n[0].startsWith('utm_'))
    .reduce((acc, n) => [...acc, { [n[0].slice(4)]: n[1] }], []);
};

export const useUTMPath = () => {
  useEffect(() => {
    const path = window.location.toString();
    const utmParams = getUTMParams(path);

    if (utmParams.length > 0) setCookie('utm_href', path);
  }, []);
};

export const useIsMobile = (width = 425) => {
  const isSsr = useIsSsr();
  const initialState = typeof window !== 'undefined' && window.innerWidth <= width;
  const [isMobile, setIsMobile] = useState(initialState);

  const handleResize = () => {
    if (window.innerWidth <= width) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (!isSsr) {
      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, [isSsr]);

  return Boolean(isMobile);
};

export const useCollapseCards = (
  collapsedCardsCount,
  projectsCount,
  countToShow = 2,
  mobileWidth = 542,
) => {
  const [countToRender, setCountToRender] = useState(collapsedCardsCount);
  const showCount = useIsMobile(mobileWidth) ? countToShow : projectsCount;

  const showMore = useCallback(() => {
    setCountToRender(prev => prev + showCount);
  }, [showCount]);

  const hide = useCallback(() => {
    setCountToRender(collapsedCardsCount);
  }, [showCount]);

  return {
    countToRender,
    renderExpandCollapseButton(seeMoreTitle, collapseTitle, seeMoreClassName, collapseClassName) {
      if (collapsedCardsCount >= projectsCount) {
        return null;
      }
      if (countToRender < projectsCount) {
        return (
          <div>
            <button onClick={showMore} className={seeMoreClassName}>
              {`${seeMoreTitle} (${projectsCount - countToRender})`}
            </button>
          </div>
        );
      }
      return (
        <div>
          <button onClick={hide} className={collapseClassName}>
            {`${collapseTitle}`}
          </button>
        </div>
      );
    },
  };
};
