import {
  VStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  HStack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';

import { msToTime } from 'shared/lib/msToTime';
import { usePodcastsPlayerContext } from 'shared/PodcastsPlayerContext';

export default function PodcastAudioProgress() {
  const [progress, setProgress] = useState(0);
  const [isCaptured, setIsCapture] = useState(false);
  const { duration, getPosition, seek } = usePodcastsPlayerContext();

  const cachedDur = useMemo(() => msToTime(duration), [duration]);

  useEffect(() => {
    const interval = setInterval(() => {
      isCaptured || setProgress(getPosition());
    }, 500);
    return () => clearInterval(interval);
  }, [isCaptured]);

  return (
    <VStack w="full" minW="160px" align="start" spacing="10px">
      <VStack w="full" spacing={0}>
        <Slider
          w="full"
          step={1}
          alignItems="center"
          focusThumbOnChange={false}
          value={progress}
          max={duration}
          onChangeStart={() => setIsCapture(true)}
          onChange={setProgress}
          onChangeEnd={val => {
            setIsCapture(false);
            seek(val);
          }}
        >
          <SliderTrack bgColor="#E3F9A4" borderRadius="full" h="1.6px" cursor="pointer">
            <SliderFilledTrack bgColor="bg.black" h="1.6px" borderRadius="full" />
          </SliderTrack>
          <SliderThumb
            boxSize="6px"
            bgColor="bg.accent"
            borderRadius="full"
            cursor="pointer"
            top="50%"
          />
        </Slider>
        <HStack w="full" fontSize="12px" fontWeight={400} justify="space-between">
          <Text>{msToTime(progress)}</Text>
          <Text>{cachedDur}</Text>
        </HStack>
      </VStack>
    </VStack>
  );
}
