import React, { useCallback, useEffect, useState } from 'react';
import { HStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { podcastsSelector } from 'app/selectors/podcastsSelector';
import PlayPauseButton from 'shared/PlayPauseButton';
import SelectTrackButton from 'shared/SelectTrackButton';
import { usePodcastsPlayerContext } from 'shared/PodcastsPlayerContext';
import { workWithTrackId } from 'shared/lib/workWithTrackId';

export default function PodcastsPlayerControl(props) {
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const { playing, togglePlayPause } = usePodcastsPlayerContext();
  const { allPodcasts, activeTrackId, setActiveTrack } = useBoundStore(podcastsSelector);

  const updateButtonStates = useCallback(() => {
    setPrevButtonDisabled(!allPodcasts[workWithTrackId(activeTrackId, 'getPrev')]);
    setNextButtonDisabled(!allPodcasts[workWithTrackId(activeTrackId, 'getNext')]);
  }, [allPodcasts, activeTrackId]);

  useEffect(() => {
    updateButtonStates();
  }, [updateButtonStates]);

  const handleSelectClick = nav => () => {
    const nextTrackId = workWithTrackId(activeTrackId, nav === 'prev' ? 'getPrev' : 'getNext');
    allPodcasts[nextTrackId] && setActiveTrack(nextTrackId);
  };

  return (
    <HStack spacing="8px" align="center" flexShrink="0" {...props}>
      <SelectTrackButton
        isDisabled={prevButtonDisabled}
        onClick={handleSelectClick('prev')}
        variant="left"
      />
      <PlayPauseButton
        isPlaying={playing}
        onPlayPauseClick={() => togglePlayPause()}
        border="none"
        boxSize={{ mobile: '40px', laptop: '42px' }}
        imgProps={{ filter: 'none' }}
      />
      <SelectTrackButton
        isDisabled={nextButtonDisabled}
        onClick={handleSelectClick('next')}
        variant="right"
      />
    </HStack>
  );
}
