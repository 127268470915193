import React, { memo, useEffect, useRef, useState } from 'react';
import { VStack, Text, Box, Image, Tooltip, Skeleton, SkeletonText } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { podcastsSelector } from 'app/selectors/podcastsSelector';
import { usePodcastsPlayerContext } from 'shared/PodcastsPlayerContext';
import PlayPauseButton from 'shared/PlayPauseButton';
import Tag from 'shared/Tag';

const NewEpisodeCard = ({ id, tag, img, title, description, isLoaded = true }) => {
  const titleRef = useRef(null);
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const { activeTrackId, setActiveTrack } = useBoundStore(podcastsSelector);
  const { playing, togglePlayPause } = usePodcastsPlayerContext();

  const handlePlayPauseButtonClick = () => {
    setActiveTrack(id);
    togglePlayPause();
  };

  useEffect(() => {
    if (titleRef.current) {
      const { scrollHeight, clientHeight } = titleRef.current;
      setIsTitleOverflow(scrollHeight > clientHeight);
    }
  }, [title]);

  return (
    <VStack
      pos="relative"
      w={{ mobile: '343px', laptop: '440px' }}
      h={{ mobile: '343px', laptop: '380px', desktop: '422px' }}
      borderRadius={{ laptop: '40px', mobile: '20px' }}
      boxSizing="border-box"
      p="20px"
      bgColor="bg.white"
      border="1.6px solid"
      borderColor="line.gray"
    >
      <Skeleton w="full" height="183px" borderRadius="32px" isLoaded={isLoaded}>
        <Box
          w="full"
          h="183px"
          borderRadius="32px"
          pos="relative"
          overflow="hidden"
          bgColor="bg.grayLight"
          flexShrink="0"
        >
          {tag && (
            <Tag
              pos="absolute"
              top="12px"
              left="12px"
              p={{ laptop: '9px 16px', mobile: '8px 14px 9px' }}
              color="bg.black"
              textStyle={{ mobile: 'tag', laptop: 'additionalTag' }}
              fontSize={{ laptop: '16px', mobile: '14px' }}
              lineHeight={{ mobile: '110%', laptop: '140%' }}
              fontWeight={{ mobile: '500', laptop: '400' }}
              zIndex="1"
            >
              {tag}
            </Tag>
          )}

          <PlayPauseButton
            isPlaying={activeTrackId === id && playing}
            onPlayPauseClick={handlePlayPauseButtonClick}
            boxSize="73px"
            imgProps={{
              boxSize: '28px',
            }}
            _hover={{
              border: 'none',
              bgColor: 'bg.accent',
              '& img': { filter: 'brightness(0) invert(1)' },
            }}
            pos="absolute"
            bottom="34px"
            left="32px"
            zIndex="1"
          />

          {img && (
            <Image
              src={img}
              alt="illustration"
              w="329px"
              h="full"
              pos="absolute"
              top="0"
              left={{ mobile: '90px', laptop: '71px' }}
              zIndex="0"
            />
          )}
        </Box>
      </Skeleton>

      <VStack
        w="full"
        h="full"
        align="start"
        spacing={{ mobile: '8px', laptop: '12px' }}
        justifyContent="space-evenly"
      >
        <SkeletonText w="full" noOfLines={2} spacing="3" skeletonHeight="5" isLoaded={isLoaded}>
          {isTitleOverflow ? (
            <Tooltip hasArrow label={title} bgColor="bg.black" closeDelay={500}>
              <Text
                as="h3"
                ref={titleRef}
                textStyle={{ mobile: 'header.h4', laptop: 'h4', desktop: 'header.h4' }}
                dangerouslySetInnerHTML={{ __html: title }}
                noOfLines={2}
              />
            </Tooltip>
          ) : (
            <Text
              as="h3"
              ref={titleRef}
              textStyle={{ mobile: 'header.h4', laptop: 'h4', desktop: 'header.h4' }}
              dangerouslySetInnerHTML={{ __html: title }}
              noOfLines={2}
            />
          )}
        </SkeletonText>
        <SkeletonText w="full" noOfLines={3} spacing="3" skeletonHeight="2" isLoaded={isLoaded}>
          <Text
            as="p"
            textStyle={{ mobile: 'h4', laptop: 'h2' }}
            fontSize={{ mobile: '16px', laptop: '18px' }}
            dangerouslySetInnerHTML={{ __html: description }}
            noOfLines={3}
          />
        </SkeletonText>
      </VStack>
    </VStack>
  );
};

export default memo(NewEpisodeCard);
