import React, { memo, useEffect, useState } from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { podcastsSelector } from 'app/selectors/podcastsSelector';
import SliderControls from 'features/SliderControls';
import NewEpisodeCard from '~entities/NewEpisodeCard';
import { useSwiperControls } from 'shared/hooks/useSwiperControls';
import BlockHeader from 'shared/BlockHeader';
import Slider from 'shared/Slider';
import Api from 'shared/backApi';

import { transformLatestData } from './lib/utils';

const NewEpisodes = () => {
  const [swiper, setSwiper] = useState();
  const { slideNext, slidePrev } = useSwiperControls(swiper);
  const { rubrics, latest, addRubricPodcasts } = useBoundStore(podcastsSelector);
  const isLoaded = Object.keys(rubrics)?.length;

  useEffect(() => {
    async function fetchRadioData() {
      try {
        const podcasts = await Api.getLatestPodcasts();
        addRubricPodcasts('latest', podcasts);
      } catch (e) {
        return e;
      }
    }
    isLoaded && fetchRadioData();
  }, [isLoaded]);

  return (
    <VStack as="section" w="full" align="flex-start" spacing={{ mobile: '24px', laptop: '48px' }}>
      <Flex w="full" justifyContent="space-between">
        <BlockHeader>Новинки</BlockHeader>
        <SliderControls slideNext={slideNext} slidePrev={slidePrev} />
      </Flex>
      <Box w="full" overflow="hidden">
        <Slider
          controller={swiper}
          setController={setSwiper}
          slidesData={
            isLoaded && latest?.length
              ? transformLatestData(latest, rubrics)
              : new Array(3).fill({ isLoaded: false })
          }
          SlideComponent={NewEpisodeCard}
          mousewheel={false}
        />
      </Box>
    </VStack>
  );
};

export default memo(NewEpisodes);
