export const workWithTrackId = (trackId, action) => {
  if (!trackId) return;

  const [rubricName, id] = trackId.split('-');

  switch (action) {
    case 'getId':
      return id;
    case 'getRubricName':
      return rubricName;
    case 'getPrev':
      return `${rubricName}-${Number(id) - 1}`;
    case 'getNext':
      return `${rubricName}-${Number(id) + 1}`;

    default:
      throw new Error('Такое действие не поддерживается');
  }
};
