import gsap from 'gsap';
import React, { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { HStack, Text } from '@chakra-ui/react';

import { useWindowResize } from 'utils/hooks';

export default function ScrolledText({ children = null, spacing = 30, ...props }) {
  const { width } = useWindowResize();
  const textRef = useRef(null);
  const containerRef = useRef(null);

  useGSAP(() => {
    const textWidth = textRef.current.offsetWidth;
    const containerWidth = containerRef.current.offsetWidth;

    gsap.killTweensOf('.text');
    gsap.set('.text', { x: 0 });

    if (textWidth > containerWidth) {
      const texts = gsap.utils.toArray('.text');

      const animationConfig = {
        duration: 13,
        repeat: -1,
        repeatDelay: 1.5,
        ease: 'linear',
      };

      gsap.fromTo(texts[0], { x: 0 }, { x: -textWidth - spacing, ...animationConfig });
      gsap.fromTo(texts[1], { x: textWidth }, { x: -spacing, ...animationConfig });
    }
  }, [width]);

  return (
    <HStack
      ref={containerRef}
      pos="relative"
      w="full"
      align="start"
      overflow="hidden"
      whiteSpace="nowrap"
    >
      <Text
        as="h5"
        ref={textRef}
        className="text"
        display="inline-block"
        textStyle="h4"
        fontSize={{ mobile: '16px', laptop: '18px' }}
        {...props}
      >
        {children}
      </Text>
      <Text
        as="h5"
        className="text"
        display={
          textRef.current?.offsetWidth > containerRef.current?.offsetWidth ? 'inline-block' : 'none'
        }
        pos="absolute"
        top="0"
        left="0"
        pl={`${spacing}px`}
        textStyle="h4"
        fontSize={{ mobile: '16px', laptop: '18px' }}
        {...props}
      >
        {children}
      </Text>
    </HStack>
  );
}
